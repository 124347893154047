<template>
  <div class="grey lighten-2 fullscreen">
    <!-- <v-btn
      text
      @click="
        $store
          .dispatch('Pago', {
            ...datos,
            Pago: $store.state.pricinPago,
            Id_Oferta: $store.state.pricingPlan,
          })
          .then((res) => {
            $router.push('/' + $store.state.iglesia.Nombre_Id);
          })
      "
    ></v-btn> -->
    <div class="flex justify-center">
      <v-card width="600" class="mt-16 mb-16" elevation="10">
        <v-card-title class="flex justify-center mt-6">
          <h3 class="headline mb-0 text-center">Ingrese sus datos para pago</h3>
        </v-card-title>
        <div class="text-center text-h4 font-weight-lighter">
          $ {{ $store.state.pricinPago }}
        </div>
        <div class="mx-6 mt-6">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                name="name"
                label="Nombre"
                id="id"
                filled
                v-model="datos.Nombre_Persona"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                name="name"
                label="Telefono"
                id="id"
                filled
                v-model="datos.Telefono"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                name="name"
                label="Email"
                id="id"
                filled
                v-model="datos.Email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                name="name"
                label="Provedor de Telefono"
                id="id"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            name="name"
            label="Codigo de promoción"
            filled
            id="id"
            v-model="datos.Codigo"
            @input="GetPromo()"
          >
            <template v-slot:prepend>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon @click="Promo()" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                Solicitar Codigo de Promoción
              </v-tooltip>
            </template>
          </v-text-field>

          <v-divider></v-divider>
          <v-btn
            v-if="codigo.length > 0"
            color="success"
            block
            class="mb-5"
            height="58"
            @click="
              $store
                .dispatch('Pago', {
                  ...datos,
                  Pago: $store.state.pricinPago,
                  Id_Oferta: $store.state.pricingPlan,
                  credit: credit,
                })
                .then((res) => {
                  $router.push('/' + $store.state.iglesia.Nombre_Id);
                })
            "
            >Continuar</v-btn
          >
          <v-row class="mb-6">
            <v-col class="green rounded">
              <v-btn
                elevation="0"
                color="green"
                class="white--text"
                block
                height="58"
              
                @click="credit = !credit"
                >Credito</v-btn
              >
            </v-col>

            <v-col class="rounded" style="background-color: #fbc439">
              <div
                ref="paypal"
                style="hieght: 58px !important"
                class="align-self-center mt-3"
              ></div>
            </v-col>
          </v-row>

          <div v-if="credit == true">
            <vue-paycard
              :background-image="foto"
              :value-fields="{
                cardName: datos.Nombre_Tarjeta,
                cardNumber: datos.Numero_Tarjeta,
                cardMonth: datos.MM,
                cardYear: datos.YYYY,
                cardCvv: datos.CVC,
              }"
            />
            <v-text-field
              name="name"
              label="Titular de Tarjeta"
              id="id"
              filled
              v-model="datos.Nombre_Tarjeta"
              class="mt-6"
              prepend-inner-icon="mdi-card-account-details"
            ></v-text-field>
            <v-text-field-simplemask
              v-model="datos.Numero_Tarjeta"
              v-bind:label="'Numero de Tarjeta'"
              v-bind:properties="{
                prefix: '',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: false,
                filled: true,
                clearable: true,
                placeholder: '',
                prependInnerIcon: 'mdi-credit-card',
              }"
              v-bind:options="{
                inputMask: '#### #### #### ####',
                outputMask: '#### #### #### ####',
                empty: '',
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              }"
            />
            <!-- <v-text-field
              name="name"
              label="Numero de Tarjeta"
              id="id"
              filled
              v-model="datos.Numero_Tarjeta"
              
            ></v-text-field> -->
            <div class="flex">
              <v-text-field-simplemask
                v-model="datos.CVC"
                v-bind:label="'CVC'"
                v-bind:properties="{
                  prefix: '',
                  suffix: '',
                  readonly: false,
                  disabled: false,
                  outlined: false,
                  filled: true,
                  clearable: true,
                  placeholder: '',
                  prependInnerIcon: 'mdi-key',
                }"
                v-bind:options="{
                  inputMask: '###',
                  outputMask: '###',
                  empty: '',
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
              />
              <v-select
                name="name"
                label="MM"
                id="id"
                filled
                class="mx-2"
                prepend-inner-icon="mdi-calendar"
                :items="[
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                  '10',
                  '11',
                  '12',
                ]"
                v-model="datos.MM"
              ></v-select>

              <v-text-field-simplemask
                v-model="datos.YYYY"
                v-bind:label="'YYYY'"
                v-bind:properties="{
                  prefix: '',
                  suffix: '',
                  readonly: false,
                  disabled: false,
                  outlined: false,
                  filled: true,
                  clearable: true,
                  placeholder: '',
                  prependInnerIcon: 'mdi-calendar',
                }"
                v-bind:options="{
                  inputMask: '####',
                  outputMask: '####',
                  empty: '',
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
              />
            </div>
            <v-btn
              block
              class="mt-6 mb-10 white--text"
              color="blue lighten-2"
              height="58"
              @click="
                $store
                  .dispatch('PagoCredito', {
                    ...datos,
                    Pago: $store.state.pricinPago,
                    Id_Oferta: $store.state.pricingPlan,
                    credit: credit,
                  })
                  .then((res) => {})
              "
              >Confirmar Pago</v-btn
            >
          </div>
          <!-- <v-btn
            block
            height="50"
            @click="credit = !credit"
            class="mb-5 white--text text-h6 capitalize"
            color="green lighten-2"
            >Pagar&nbsp;<span class="lowercase"> con</span> tarjeta&nbsp;<span
              class="lowercase"
              >de</span
            >
            credito</v-btn
          >
          <div ref="paypal" class="mb-3"></div> -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Foto from "../assets/images/blue.jpg";
import { VuePaycard } from "vue-paycard";
import VuetifyMask from "vuetify-mask";
export default {
  components: {
    VuePaycard,
    VuetifyMask,
  },
  data() {
    return {
      foto: Foto,
      tab: "",
      codigo: [],
      pricinPago: "",
      credit: false,
      datos: {
        Nombre_Personal: "",
        Numero_Tarjeta: "",
        CVC: "",
        MM: "",
        YYYY: "",
        Telefono: "",
        Codigo: "",
        Email: "",
        Nombre_Tarjeta: "",
      },
    };
  },
  created() {
    this.OpenPaypal();
  },

  methods: {
    OpenPaypal() {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=" +
        "AXtCIDCjSq9deoO24d0srKVXncLpIZFP4kxb_pTcy8Zb2UpC6d7HXL0MRnuLfiseOG6ZC-teyG11wpfm" +
        "&disable-funding=credit,card";
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
    },

    setLoaded: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "",
                  amount: {
                    currency_code: "USD",
                    value: this.$store.state.pricinPago,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            // this.$store
            //   .dispatch("post_Donacion", {
            //     Nombre: this.datos.Nombre,
            //     Telefono: this.datos.Telefono,
            //     pago: this.$store.state.pricinPago,
            //     tipo: "Paypal",
            //     Numref: order.id,
            //     Fecha: moment().format("MM/DD/YYYY"),
            //   })
            //   .then((response) => {});
            this.$store
              .dispatch("Pago", {
                ...this.datos,
                Pago: this.$store.state.pricinPago,

                Id_Oferta: this.$store.state.pricingPlan,
              })
              .then((res) => {
                this.$router.push("/" + $store.state.iglesia.Nombre_Id);
              });
            console.log(order);
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
      setTimeout(() => {
        console.log(document.getElementsByName("iframe"));
      }, 500);
    },

    Promo() {
      if (
        this.datos.Nombre != "" &&
        this.datos.Email != "" &&
        this.datos.Telefono != ""
      ) {
        this.$store.dispatch("GeneratePromo", this.datos).then((res) => {
          // this.datos.Codigo = res;
        });
      } else {
        alert(
          "Favor de llenar Nombre, Email y Telefono para solicitar Codigo de Promoción"
        );
      }
    },
    GetPromo() {
      this.$store.dispatch("GetPromo", this.datos).then((res) => {
        console.log(res);
        if (res.length > 0) {
          this.codigo = res;
          this.$store.state.pricinPago = 0;
        } else {
          this.codigo = "";
          this.$store.state.pricinPago = this.$store.state.pricinPagoCopy;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.card-item {
  border-radius: 0 !important;
  width: 100% !important;
  // margin: 0 !important;
}
.card-item__side {
  width: 100% !important;
  box-shadow: none !important;
}
</style>